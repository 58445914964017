<template>
  <div class="page-container">
    <va-button color="primary" @click="openCreateMatchModal">Create New Match</va-button>
    <br>
    <br>
    <br>
    <div class="match-list">

      <v-card v-for="match in matches" :key="match.id" class="match-card">
        <v-card-title>{{ match.currency }}</v-card-title>
        <v-card-subtitle>Entry: {{ match.entry }}</v-card-subtitle>
        <v-card-subtitle>Player Limit: {{ match['player-limit'] }}</v-card-subtitle>
        <v-card-text>Prize: {{ match.prize }}</v-card-text>
        <v-card-actions class="action-buttons">
          <va-button color="primary" @click="openEditMatchModal(match)">Edit</va-button>
          <va-button color="danger" @click="deleteMatch(match,$event)">Delete</va-button>
        </v-card-actions>
      </v-card>
    </div>

    <match-modal
      v-model="isMatchModalOpen"
      :selected-match="selectedMatch"
      @save="saveMatch"
      @close="closeMatchModal"
    ></match-modal>
  </div>
</template>


<script>
import { ref, onMounted,watch } from "vue";
import MatchModal from "./MatchModal.vue";
import store from "@/store";
import axios from "axios"; // Import the MatchModal component

const API_URL = `${process.env.VUE_APP_SKIP_BASE_URL}/v1/match/type`; // Replace with your API URL

export default {
  components: {
    MatchModal,
  },
  setup() {
    const matches = ref([]);
    const isMatchModalOpen = ref(false);
    const selectedMatch = ref(null);

    watch(isMatchModalOpen, (newValue) => {
      console.log("isMatchModalOpen changed to:", newValue);
    });


    async function fetchMatches() {
      try {
        // const response = (await axios.get(`${process.env.VUE_APP_SKIP_BASE_URL}/v1/match/type`,{
        //   headers:{
        //     'Content-Type':'application/json',
        //     'token':store.getters.token,
        //   }
        // }))
        const response = await fetch(`${process.env.VUE_APP_SKIP_BASE_URL}/v1/match/type`,{headers:{'token':store.getters.token,'Content-Type':'application/json'}});
        const data = await response.json();
        console.log(data.data)
        matches.value = data.data;
        console.log(matches.value)
      } catch (error) {
        console.error("Failed to fetch matches:", error);
      }
    }

    function openCreateMatchModal() {
      console.log("Opening Create Match Modal");
      selectedMatch.value = null;
      isMatchModalOpen.value = true;
    }

    function openEditMatchModal(match) {
      selectedMatch.value = match;
      isMatchModalOpen.value = true;
    }

    function closeMatchModal() {
      isMatchModalOpen.value = false;
    }

    async function deleteMatch(match) {
      console.log("start deleting match")

      const response = await fetch(`${process.env.VUE_APP_SKIP_BASE_URL}/v1/match/type?id=${match.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "token": store.getters.token
        },

      });


      console.log(  match.id)
      console.log(this.matches)
      if (response.status === 200){
        console.log(" i am here ")
        const updatedMatchIndex = matches.value.findIndex(
          (m) => m.id === match.id
        );
        matches.value.pop(updatedMatchIndex);
      }
      console.log(this.matches)

    }

    async function saveMatch(newMatch) {
      console.log('doobl fuck')

      const response = await fetch(`${process.env.VUE_APP_SKIP_BASE_URL}/v1/match/type`, {
        method: newMatch.id ? "PATCH" : "PUT",
        headers: {
          "Content-Type": "application/json",
          "token": store.getters.token
        },
        body: JSON.stringify(newMatch),
      });

      const savedMatch = await response.json();
      console.log(savedMatch)
      if (newMatch.id) {
        console.log("hello from new match id")
        // Update existing match
        const updatedMatchIndex = matches.value.findIndex(
          (match) => match.id === newMatch.id
        );
        matches.value.splice(updatedMatchIndex, 1, savedMatch.data);
      } else {
        // Create new match
        matches.value.push(savedMatch.data);
      }
    }

    onMounted(fetchMatches);


    return {
      matches,
      isMatchModalOpen,
      selectedMatch,
      openCreateMatchModal,
      openEditMatchModal,
      closeMatchModal,
      saveMatch,
      deleteMatch
    };
  },

};
</script>



<style scoped>
.action-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.v-btn {
  flex: 1;
  margin: 5px;
}
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.match-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  max-width: 100%;
  width: 1200px;
  margin-bottom: 20px;
}

.match-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  transition: box-shadow 0.3s, transform 0.3s;
}

.match-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.match-card h4 {
  margin-bottom: 10px;
}

.match-card button {
  margin-top: 10px;
}

.new-match-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: #3abaf4;
  color: #fff;
  border-radius: 50%;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s;
}

.new-match-button:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.new-match-button i {
  vertical-align: middle;
}
</style>





