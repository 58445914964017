<!-- MatchModal.vue -->
<template>

  <va-modal class="match-modal" :value="isModalOpen"  @input="onModalClose" :title="modalTitle">
    <va-form ref="mform" @submit.prevent="save" class="match-form">
      <!--      {{ match}}-->
      <div class="form-row">
        <va-select
          v-model="matchForm.currency"
          label="Currency"
          :options="currencyOptions"
          :rules="[requiredRule]"
          required
        ></va-select>

        <va-input
          v-model.number="matchForm.entry"
          label="Entry"
          placeholder="Enter match entry"
          :rules="[requiredRule]"
          required
        ></va-input>

      </div>

      <div class="form-row">

        <va-input
          v-model.number="matchForm.prize"
          label="Prize"
          placeholder="Enter match prize"
          :rules="[requiredRule]"
          required
        ></va-input>

        <va-input
          v-model.number="matchForm['player-limit']"
          label="Player Limit"
          placeholder="Enter player limit"
          :rules="[requiredRule]"
          required
        ></va-input>

      </div>

      <div class="submit-button">
        <va-button type="submit" color="success" @click="save">Save</va-button>
      </div>
    </va-form>
  </va-modal>
</template>

<script>
import { ref, watch } from "vue";


const requiredRule = (v) => !!v || "This field is required";

export default {
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    selectedMatch: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "save"],
  setup(props, { emit }) {



    const matchForm = ref({
      id: "",
      currency: "",
      entry: 0,
      prize: 0,
      "player-limit": 0,
    });
    const modalTitle = ref("");
    const mform = ref(null);
    const currencyOptions = [
      'token',
      'coin'
      // Add more currency options here
    ];



    function onModalClose(isOpen) {
      if (!isOpen) {
        emit("close");
      }
    }

    async function save() {
      console.log("Saving match:", matchForm.value);
      console.log("mform: ", mform)
      const isValid = await mform.value.validate();
      if (!isValid) {
        return;
      }
      emit("save", matchForm.value);
    }



    watch(
      () => props.selectedMatch,
      (selectedMatch) => {
        if (selectedMatch) {
          matchForm.value = { ...selectedMatch };
        } else {
          matchForm.value = {
            id: "",
            currency: "",
            entry: 0,
            prize: 0,
            'player-limit': 0,
          };
        }
      },
      { immediate: true }
    );

    watch(
      () => props.selectedMatch,
      (selectedMatch) => {
        modalTitle.value = selectedMatch ? "Edit Match" : "Create New Match";
      },
      { immediate: true }
    );

    return {
      matchForm,
      onModalClose,
      save,
      modalTitle,
      requiredRule,
      currencyOptions,
      mform
    };
  },
};
</script>


<style >

.va-modal--mobile-fullscreen {
  border-radius: initial !important;
  margin: initial !important;
  min-height: initial !important;
  min-width: initial !important;
  position: initial !important;
}
.match-modal {
  max-width: calc(100% - 20px);
  margin: 10px;
}

.match-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: column;
}

.form-row > div {
  display: flex;
  flex-direction: column;
}

.field-label {
  margin-bottom: 5px;
}

.submit-button {
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
}

.va-button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.circular-progress {
  margin-left: 10px;
}

/* Media query for mobile devices */
@media (max-width: 576px) {
  .match-modal {
    position: center;
  }

  .match-form {
    grid-gap: 5px;
  }


}

</style>
